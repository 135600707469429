<template>
  <div class="editAdd">
    <GlobalInfoBar title="报案理赔详情"
      subtitle="“待补充资料”：代表资料缺失，我们会调补充报案接口; “审核不通过”：代表理赔单结束，需要修改报案信息重新报案; “审核通过”：代表资料没问题，进入赔款操作" />
    <GlobalChunk title="报案单信息" :padding="[32, 0, 32, 55]">
      <el-form ref="form" :model="form" label-width="160px">
        <template v-for="item in formItems">
          <el-form-item :label="item.label" :key="item.prop">
            <!--输入框-->
            <el-input v-if="item.type === 'input'" v-model="form[item.prop]" :placeholder="'请输入' + item.label"
              :disabled="item.disabled"></el-input>

            <!--选择框-->
            <el-select v-if="item.type === 'select' &&
              item.optValue &&
              item.optLabel &&
              !item.multiple
              " v-model="form[item.prop]" :placeholder="'请选择' + item.label" filterable :clearable="item.clearable"
              :disabled="item.disabled" @change="selectChange(item.relate)">
              <el-option v-for="opt in getOptions(item.optListName)" :key="opt[item.optValue]" :label="opt[item.optLabel]"
                :value="opt[item.optValue]">
              </el-option>
            </el-select>

            <!-- 可多选 -->
            <el-select v-if="item.type === 'select' &&
              item.optValue &&
              item.optLabel &&
              item.multiple
              " :multiple="true" filterable :clearable="item.clearable" v-model="form[item.prop]"
              :placeholder="'请选择' + item.label" :disabled="item.disabled" @change="selectChange(item.relate)">
              <el-option v-for="opt in getOptions(item.optListName)" :key="opt[item.optValue]" :label="opt[item.optLabel]"
                :value="opt[item.optValue]">
              </el-option>
            </el-select>

            <el-select v-if="item.type === 'select' && !item.optValue && !item.optLabel" v-model="form[item.prop]"
              :placeholder="'请选择' + item.label" filterable :clearable="item.clearable" :disabled="item.disabled"
              @change="selectChange(item.relate)">
              <el-option v-for="opt in getOptions(item.optListName)" :key="opt" :label="opt" :value="opt">
              </el-option>
            </el-select>

            <!--日期框-->
            <el-date-picker v-if="item.type === 'date'" v-model="form[item.prop]" align="right" type="datetime"
              :placeholder="'请选择' + item.label" value-format="yyyy-MM-dd HH:mm:ss" :disabled="item.disabled">
            </el-date-picker>

            <!--dateRange -->
            <el-date-picker v-if="item.type === 'dateRange'" type="datetimerange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" v-model="dateRange"
              :value="[form[item.props[0]], form[item.props[1]]]">
            </el-date-picker>

            <!--textarea-->
            <el-input v-if="item.type === 'textarea'" v-model="form[item.prop]" type="textarea"
              :placeholder="'请填写' + item.label" :disabled="item.disabled"></el-input>

            <!--radio-->
            <el-radio-group v-if="item.type === 'radio'" v-model="form[item.prop]">
              <el-radio v-model="form[item.prop]" :label="1">审核中</el-radio>
              <el-radio v-model="form[item.prop]" :label="2">审核通过</el-radio>
              <el-radio v-model="form[item.prop]" :label="3">赔款中</el-radio>
              <el-radio v-model="form[item.prop]" :label="4">赔款完成</el-radio>
              <el-radio v-model="form[item.prop]" :label="-1">审核不通过</el-radio>
              <el-radio v-model="form[item.prop]" :label="-2">拒赔</el-radio>
              <el-radio v-model="form[item.prop]" :label="-3">待补充资料</el-radio>
              <el-radio v-model="form[item.prop]" :label="-4">取消</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>

        <div class="bottom-btns">
          <el-button type="primary" @click="reportPush">报案推送</el-button>
          <el-button type="primary" @click="claimPush">理赔推送</el-button>
          <el-button type="primary" @click="examinePush">资料审核</el-button>
        </div>
      </el-form>
    </GlobalChunk>
    <GlobalChunk title="报案单-图片信息" :padding="[32, 0, 32, 55]" style="margin-top: 20px">
      <GlobalTable ref="GlobalTable" :columns="tableColumns" :data="tableData" :isPagination="false">
        <el-table-column label="图片连接" slot="fileUrl" align="center">
          <template slot-scope="{ row }">
            <a :href="row.fileUrl" target="_blank">{{ row.fileUrl }}</a>
          </template>
        </el-table-column>
        <el-table-column label="图片内容" slot="fileContent" align="center">
          <template slot-scope="{ row }">
            <el-image class="preview-img" :src="row.fileUrl" :data-watermark="true" :preview-src-list="[row.fileUrl]" @click.stop="closePreview">
            </el-image>
            <!-- <img :src="row.fileUrl" width="100px" height="80px"> -->
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import api from '@/utils/request'
import { imageUp } from "../../utils/request/changePhone";
export default {
  name: "acceptDetail",
  props: { reportId: [String, Number] },
  data() {
    return {
      reportInfo: {},//基本信息
      form: {},
      formItems: [
        {
          label: "报案单号",
          prop: "reportNo",
          value: "csgsehtru567k12143464",
          type: "input"
        },
        {
          label: "流水号",
          prop: "serialNo",
          value: "lslslsl",
          type: "input"
        },
        {
          label: "保单号",
          prop: "policyNo",
          value: "21568gehrthrj",
          type: "input"
        },
        {
          label: "报案人姓名",
          prop: "reporterName",
          value: "李奕娴",
          type: "input"
        },
        {
          label: "报案人电话",
          prop: "reporterTel",
          value: "15678941234",
          type: "input"
        },
        {
          label: "出险时间",
          prop: "accidentDate",
          value: "2021-03-10 17:30:25",
          type: "date"
        },
        {
          label: "报案时间",
          prop: "reportDate",
          value: "2023-10-10 17:30:25",
          type: "date"
        },
        {
          label: "报损金额（分）",
          prop: "reportLossSum",
          value: 100,
          type: "input"
        },
        {
          label: "故障描述",
          prop: "accidentDesc",
          value: "求救~~~~",
          type: "textarea"
        },
        {
          label: "报案单号",
          prop: "deviceModel",
          value: "x90s",
          type: "input"
        },
        {
          label: "设备唯一标识IMEI",
          prop: "deviceIdentity",
          value: "652656512239812",
          type: "input"
        },
        {
          label: "产品编码",
          prop: "productCode",
          value: "SJSPB001",
          type: "input"
        },
        {
          label: "产品责任编码",
          prop: "planCode",
          value: "10001",
          type: "input"
        },
        {
          label: "accidentArea",
          prop: "accidentArea",
          value: "地球北极圈",
          type: "input"
        },
        {
          label: "维修门店编码",
          prop: "shopCode",
          value: "门店编码**",
          type: "input"
        },
        {
          label: "维修单号",
          prop: "mendOrderNo",
          value: "工单号",
          type: "input"
        },
        {
          label: "维修配件名称",
          prop: "mendPartName",
          value: "屏幕,电池",
          type: "input"
        },
        {
          label: "维修配件数量",
          prop: "mendPartCount",
          value: "1",
          type: "input"
        },
        {
          label: "维修配件金额（分）",
          prop: "mendPartPrice",
          value: "500,200",
          type: "input"
        },
        {
          label: "开户行代码",
          prop: "bankCode",
          value: "91510105MA678978944",
          type: "input"
        },
        {
          label: "开户行名称",
          prop: "bankName",
          value: "中国农业银行股份有限公司成都锦城支行",
          type: "input"
        },
        {
          label: "账户名称",
          prop: "bankAccountName",
          value: "成都震响科技有限公司",
          type: "input"
        },
        {
          label: "银行卡号",
          prop: "bankCardNo",
          value: "128910837595955",
          type: "input"
        },
        {
          label: "添加时间",
          prop: "add_time",
          value: "2023-10-11 14:34:13",
          type: "date"
        },
        {
          label: "理赔状态",
          prop: "claimStatus",
          value: "1",
          type: "radio"
        },
        {
          label: "审核描述",
          prop: "auditDesc",
          value: "lslslsl",
          type: "textarea"
        },
        // {
        //   label: "xxx",
        //   prop:"auditTime",
        //   value:"21568gehrthrj",
        //   type:"date"
        // },
        //        {
        //   label: "xxx",
        //   prop: "settleTime",
        //   value:"lslslsl",
        //   type:"date"
        // },
        // {
        //   label: "xxx",
        //   prop:"indemnityTime",
        //   value:"21568gehrthrj",
        //   type:"date"
        // },
        //     {
        //   label: "xxx支付金额？",
        //   prop: "payAmount",
        //   value:"lslslsl",
        //   type:"input"
        // },
        //     {
        //   label: "xxx？银行单号",
        //   prop: "bankSerialNo",
        //   value:"lslslsl",
        //   type:"input"
        // }
      ],
      // formData: {},
      dateRange: [],
      tableData: [],
      tableColumns: [
        { label: "图片名称", prop: "fileName" },
        { slotName: "fileContent" },
        { label: "图片类型", prop: "fileType" },
        { label: "图片描述", prop: "fileDesc" }
      ],
    }
  },
  mounted() {
    api.settleReportDetail({
      reportId: this.reportId
    }).then(res => {
      this.form = res.data.report || {}
      this.tableData = res.data.imgs
    })
  },
  methods: {
    closePreview(event) {
      console.log(event.target.dataset.watermark,this.form.deviceIdentity);
      // 获取遮罩层dom
      setTimeout(() => {
        let domImageMask = document.querySelector(".el-image-viewer__wrapper");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", (e) => {
          if (
            e.target.parentNode.className ===
            "el-image-viewer__actions__inner" ||
            e.target.parentNode.className === "el-image-viewer__canvas"
          ) {
            return; //如果点击底部菜单，不关闭
          }
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });

        //添加水印
        if (event.target.dataset.watermark) {
          let div = document.createElement("div");
          let text = "";
          
          if (this.form.deviceIdentity) {
            text += `<p>IMEI码：${this.form.deviceIdentity}</p>`;
          }
          div.innerHTML = text;
          div.classList = ["watermark"];
          domImageMask.appendChild(div);
        }
      }, 300);
    },
    reportPush() {
      this.$confirm('是否报案推送', '报案推送', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.reportPush(this.form).then((res) => {
          this.$message.success(res.msg || "报案推送成功");
        }).catch((err) => { });
      })

    },

    claimPush() {
      this.$confirm('是否理赔推送', '理赔推送', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.claimPush(this.form).then((res) => {
          this.$message.success(res.msg || "理赔推送成功");
        }).catch((err) => { });
      })
    },

    examinePush() {
      this.$confirm('是否进行资料审核', '资料审核', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.examinePush(this.form).then((res) => {
          this.$message.success(res.msg || "资料审核成功");
        }).catch((err) => { });
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select,
.el-date-picker,
.el-textarea,
.el-date-editor,
::v-deep .el-input__inner {
  width: 500px !important;
}

::v-deep .img {
  width: 100%;
}
::v-deep .watermark {
  position: absolute;
  left: 220px;
  max-width: 400px;
  line-height: 1.5em;
  word-break: break-all;
  top: 60px;
  color: #fff;
  background: #00000080;
  border-radius: 10px;
  padding: 15px;
}

.transfer-agreement {
  margin-left: 40px;

  .title {
    font-weight: bold;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 15px;
      background: #1091FF;
      border-radius: 1px;
    }
  }
}

.generate-img {
  float: right;
}

.preview-img {
  width: 148px;
  height: 148px;
  border-radius: 5px;
}

.bottom-btns {
  margin-left: 160px;
}
</style>
